import { useDisclosure } from '@chakra-ui/react'
import { ModalSelect } from '@components/Modal/enum'
import ModalOpenAccount from '@components/ModalOpenAccount'
import React, { createContext } from 'react'

let DisclosureContext: React.Context<(modalSelect: ModalSelect) => void>
const { Provider } = (DisclosureContext = createContext<(modalSelect: ModalSelect) => void>(
  () => ModalSelect.OpenAccount
))

const ModalProvider = ({ children, openYourAccountAlert }) => {
  const { isOpen, onOpen: handleOpenYourAccount, onClose: onCloseOpenAccount } = useDisclosure()

  const openModal = (modalSelect: ModalSelect) => {
    // Addicionar demais modais ou disclosure aqui nesse array
    if (modalSelect === ModalSelect.OpenAccount) {
      return handleOpenYourAccount()
    }
  }
  return (
    <Provider value={openModal}>
      {/* //usar o mesmo provider para adicionar novas somente adiciona Aqui o componente da modal */}
      {openYourAccountAlert && (
        <ModalOpenAccount isOpen={isOpen} onClose={onCloseOpenAccount} openYourAccountAlert={openYourAccountAlert} />
      )}
      {children}
    </Provider>
  )
}

export { DisclosureContext, ModalProvider }
