import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import breaks from 'remark-breaks'
import styles from './Markdown.module.scss'

const renderers = {
  // eslint-disable-next-line react/display-name
  image: ({ src }) => <img src={src} />,
}

const markdownPlugins = [gfm, breaks]

interface IProps {
  children: string
}

const Markdown: React.FC<IProps> = ({ children }) => {
  children = children?.replace(/\n/gi, '&nbsp; \n')
  return (
    <ReactMarkdown className={styles.markdown} renderers={renderers} plugins={markdownPlugins}>
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
