import { ModalSelect } from '@components/Modal/enum'
import { DisclosureContext } from '@components/Modal/provider'
import { IPageInternal } from '@src/interfaces/Home.interface'
import Link from 'next/link'
import React, { useCallback, useContext } from 'react'
import styles from './GenerateLink.module.scss'

export const getExternalLinkProps = (type?: string, external?: boolean | null) => {
  if (type === 'external' || external) return { target: '_blank', rel: 'noopener noreferrer' }
  if (type === 'file') return { target: '_blank', rel: 'noopener noreferrer', download: true }

  return {}
}

export const ref = (
  type?: string,
  url?: string | null,
  page?: IPageInternal | null,
  file?: any,
  external?: boolean | null
) => {
  if ((type === 'external' || external) && url) return url
  if (type === 'file' && file) return Array.isArray(file) ? file[0].url : file.url
  return `/${page?.key}`
}
export interface ILinkProps {
  id?: number
  external?: boolean | null
  url?: string | null
  page?: any
  label?: string
  type?: 'internal' | 'external' | 'file' | 'modal'
  file?: any
  modalSelect?: ModalSelect
}

export const GenerateLinkIcon: React.FC<ILinkProps> = props => {
  if (!props.label) return null

  return (
    <div className={styles.link}>
      <GenerateLink {...props}>
        <div className={styles.title}>{props.label}</div>
        <img src="/arrow.svg" />
      </GenerateLink>
    </div>
  )
}

export const GenerateLink: React.FC<ILinkProps> = ({
  external,
  url,
  page,
  children,
  label,
  type,
  file,
  modalSelect,
}) => {
  const hRef = ref(type, url, page, file, external)

  const GenerateLinkWithModal: React.FC<any> = useCallback(
    ({ modalSelect, children }) => {
      const openModal = useContext(DisclosureContext)
      const openHandler = (event: any, modalSelect: ModalSelect) => {
        event.preventDefault()
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()

        if (['h1', 'a'].includes(event.target?.localName)) {
          // workaround para nao clickar no banner o stopPropagation era para resolver
          // mas quando usa o componente do chakra nao esta indo ele sempre clicka nos 2
          // na versao nova esta corrigido aparentemente
          //
          return
        }
        openModal(modalSelect)
      }

      return (
        <Link href={{}} passHref>
          <a onClick={e => openHandler(e, modalSelect)}>{children}</a>
        </Link>
      )
    },
    [modalSelect]
  )
  if (type === 'modal' && modalSelect) {
    return (
      <GenerateLinkWithModal modalSelect={modalSelect} label={label}>
        {children || label}
      </GenerateLinkWithModal>
    )
  }

  return (
    <Link href={hRef} passHref>
      <a {...getExternalLinkProps(type, external)}>{children || label}</a>
    </Link>
  )
}
