import { ILinkProps } from '@components/GenerateLink'
import IconListWithLink, { IIconListWithLink } from '@components/IconListWithLink'
import Markdown from '@components/Markdown'
import useWindowSize from '@src/hooks/useWindowSize'
import Image from 'next/image'
import { FC } from 'react'
import styles from './CardOpenAccount.module.scss'

export interface CardOpenAccountProps {
  link: ILinkProps
  title: string
  subtitle: string
  descriptionDesktop: string
  descriptionMobile: string
  image: any
  footerList: any
}

export interface Props {
  info: CardOpenAccountProps
  iconListWithLink: IIconListWithLink[]
}

const CardOpenAccount: FC<Props> = ({ info, iconListWithLink }) => {
  const { width } = useWindowSize()

  const isMobile = () => {
    return width && width <= 1200
  }

  const renderIconListWithLink = (orientation: 'column' | 'row') => (
    <div className={styles.icons}>
      {iconListWithLink && iconListWithLink.map(props => IconListWithLink({ ...props, orientation }))}
    </div>
  )

  const renderDesktop = () => (
    <>
      <div className={styles.description}>
        <Markdown>{info?.descriptionDesktop}</Markdown>
      </div>
      <div className={styles.cardIconsImg}>
        <div>{info?.image?.url && <Image src={info?.image?.url} width={180} height={180} />}</div>
        {renderIconListWithLink('column')}
      </div>
    </>
  )

  const renderMobile = () => (
    <>
      <div className={styles.description}>
        <Markdown>{info?.descriptionMobile}</Markdown>
      </div>
      <div className={styles.cardIconsImg}>{renderIconListWithLink('row')}</div>
    </>
  )

  return (
    <div className={styles.card}>
      <div className={styles.title}>
        <Markdown>{info?.title}</Markdown>
      </div>
      <div className={styles.subtitle}>{info?.subtitle}</div>
      {isMobile() ? renderMobile() : renderDesktop()}
    </div>
  )
}

export default CardOpenAccount
