import { CloseButton, Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/core'
import CardOpenAccount from '@components/CardOpenAccount'
import { GenerateLink } from '@components/GenerateLink'
import { Text } from 'domelementtype'
import { FC } from 'react'
import styles from './ModalOpenAccount.module.scss'

export interface ModalOpenAccountProps {
  openYourAccountAlert: any
}

export interface IModalDetailedProps {
  isOpen: boolean
  onClose: () => void
}

const ModalOpenAccount: FC<ModalOpenAccountProps & IModalDetailedProps> = ({
  isOpen,
  onClose,
  openYourAccountAlert,
}) => {
  const { info } = openYourAccountAlert
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={'md'}>
        <DrawerOverlay bg="#0d6ff031" />
        <DrawerContent padding={{ justifyContent: 'flex', base: '1rem 0', lg: '1rem' }}>
          <DrawerBody>
            <div className={styles.headerGroup}>
              <div onClick={onClose}>
                <CloseButton size="sm" className={styles.closeButton} />
                <Text className={styles.closeText}>{'Fechar'}</Text>
              </div>
              <div className={styles.linkInfo}>
                {info?.link && <GenerateLink {...info.link}>{info?.link.label}</GenerateLink>}
              </div>
            </div>
            <div className={styles.contentBody}>
              <div>
                <CardOpenAccount {...openYourAccountAlert} />
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default ModalOpenAccount
